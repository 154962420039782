import { JSEncrypt } from 'jsencrypt'
let publicKey = process.env.VUE_APP_RSA_PUBLIC_KEY;
/**
 * 数据加密
 * @param data
 * @returns {string | false | Promise<ArrayBuffer>}
 */
export function encrypt(data) {
    //实例化jsEncrypt对象
    let jse = new JSEncrypt();
    //设置公钥
    jse.setPublicKey(publicKey);
    return jse.encrypt(data);
}